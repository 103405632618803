import React from 'react';
import { PhoneMissed, PhoneOutgoing, PhoneIncoming, Voicemail } from 'react-feather';
import { CallType } from '../../types/crm/callActivity';

interface CallStatusIconProps {
  status: CallType;
}

const CallStatusIcon: React.FC<CallStatusIconProps> = ({ status }) => {
  switch (status) {
    case CallType.INCOMING_CALL:
      return <PhoneIncoming color="#28a745" />;
    case CallType.OUTGOING_CALL:
      return <PhoneOutgoing color="#28a745" />;
    case CallType.VOICEMAIL:
      return <Voicemail color="#FFA500" />;
    default:
      return <PhoneMissed color="#dc3545" />;
  }
};

export default CallStatusIcon;
