import React, { useContext, useEffect, useState } from 'react';
import { CurrentUserContext } from '../../contexts/CurrentUserContext';
import NucleusPage from '../components/NucleusPage';
import { DataGrid, GridCellParams, GridColDef, GridPaginationModel } from '@mui/x-data-grid';
import EllipsisText from '../components/EllipsisText';
import { DateFormatter, DateFormatType } from '../components/DateFormatter';
import { Box, Card, CardContent, CardMedia } from '@mui/material';
import { useQuery } from '@apollo/client';
import { CallPayloadStatusEnum } from '../types/crm/callPayload';
import LetterAvatar from '../components/Avatar';
import { ContactDetails } from './contacts/profile/ContactDetails';
import { formatDuration } from '../../helpers/durationFormatter';
import CallStatusIcon from './callActivity/CallActivityCallStatusIcon';
import { USER_CALL_ACTIVITIES } from '../../common/graphQL/call/callActivity/queries';
import { CrmUserCallActivities, CrmUserCallActivity, getCallerName } from '../types/crm/callActivity';
import { RootState } from '../../redux/store';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setCallActivityData, setIsDataLoaded, setTotalCalls } from '../../redux/callActivitySlice';
import { CrmLead } from '../types/crm/lead';

function CallActivity() {
  const dispatch = useAppDispatch();
  const currentUser = useContext(CurrentUserContext);
  const callActivityData = useAppSelector<CrmUserCallActivity[]>((state: RootState) => state.callActivity.data);
  const callActivityCount = useAppSelector<number>((state: RootState) => state.callActivity.totalCalls);
  const isDataLoaded = useAppSelector<boolean>((state: RootState) => state.callActivity.isDataLoaded);

  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 25,
  });

  const { data: callActivityResults, loading: loadingAllCallLogs } = useQuery<CrmUserCallActivities>(
    USER_CALL_ACTIVITIES,
    {
      variables: {
        filter: {
          status: [
            CallPayloadStatusEnum.BUSY,
            CallPayloadStatusEnum.CANCELED,
            CallPayloadStatusEnum.NO_ANSWER,
            CallPayloadStatusEnum.FAILED,
            CallPayloadStatusEnum.COMPLETED,
          ],
        },
        pagination: {
          offset: paginationModel.page * paginationModel.pageSize,
          limit: paginationModel.pageSize,
        },
      },
      errorPolicy: 'all',
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    },
  );

  useEffect(() => {
    if (callActivityResults && callActivityResults?.crm?.userOptions?.userCallActivities?.callActivities) {
      dispatch(setCallActivityData(callActivityResults?.crm?.userOptions?.userCallActivities?.callActivities));
      dispatch(setIsDataLoaded(true));
      dispatch(setTotalCalls(callActivityResults?.crm?.userOptions?.userCallActivities?.totalCount));
    }
  }, [callActivityResults, dispatch]);

  const columns: GridColDef[] = [
    {
      field: 'callerName',
      headerName: 'Calls',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      editable: false,
      renderCell: (params: GridCellParams) => {
        const contactNo = params.row?.caller ?? '';

        const name = getCallerName(params.row.callerData);

        return (
          <React.Fragment>
            {
              <Box sx={{ mr: 5, display: 'inline-grid' }}>
                <CallStatusIcon status={params?.row?.callType} />
              </Box>
            }
            {name ? (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {name && (
                  <LetterAvatar name={name ?? ''} sx={{ backgroundColor: 'orange', width: '26px', height: '26px' }} />
                )}
                <Box sx={{ pl: 1, flex: 1, display: 'inline-grid' }}>
                  {name ? <EllipsisText content={name} /> : <EllipsisText content={'Unknown'} />}
                </Box>
              </Box>
            ) : (
              <>{contactNo ? <EllipsisText content={contactNo} /> : <EllipsisText content={'Not Provided'} />}</>
            )}
          </React.Fragment>
        );
      },
    },
    {
      field: 'date',
      headerName: 'Date',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      editable: false,
      renderCell: (params: GridCellParams) => {
        if (!params.row?.createdDate) {
          return <EllipsisText content={'--'} />;
        }

        return (
          <EllipsisText
            content={DateFormatter({
              date: params.row.createdDate,
              dateFormatType: DateFormatType.ShortDateWithTime,
            })}
          />
        );
      },
    },
    {
      field: 'duration',
      headerName: 'Duration',
      headerAlign: 'left',
      align: 'left',
      editable: false,
      renderCell: (params: GridCellParams) => {
        if (!params.row?.duration) {
          return <EllipsisText content={'--'} />;
        }

        const content =
          params.row.logType === 'voicemail'
            ? 'voicemail'
            : params.row.duration === '0'
              ? '--'
              : formatDuration(params.row.duration);

        return <EllipsisText content={content} />;
      },
    },
    {
      field: 'mediaUrl',
      headerName: 'Recording',
      headerAlign: 'left',
      align: 'left',
      editable: false,
      flex: 1,
      renderCell: (params: GridCellParams) => {
        if (!params.row?.mediaUrl) {
          return <EllipsisText content={'--'} />;
        }

        return <CardMedia component={'audio'} controls src={params.row.mediaUrl!} style={{ height: '60%' }} />;
      },
    },
    {
      field: 'action',
      headerName: 'Action',
      headerAlign: 'left',
      align: 'left',
      editable: false,
      flex: 1,
      renderCell: (params: GridCellParams) => {
        if (!params.row?.callerData) {
          return 'Contact features are unavailable.';
        }

        if (params.row?.callerData.__typename === 'CrmUser') {
          return 'Contact features are unavailable.';
        }

        return (
          <ContactDetails
            currentLead={params.row.callerData as CrmLead}
            currentUser={currentUser!}
            onlyShowActions={true}
            compact={true}
          />
        );
      },
    },
  ];

  return (
    <NucleusPage
      title="Call Activity"
      header={'Call Activity'}
      loading={!isDataLoaded}
      content={
        <React.Fragment>
          <Card sx={{ mt: 3, height: '100%', overflow: 'auto' }}>
            <CardContent
              sx={{
                padding: 0,
                height: '100%',
                '&:last-child': {
                  paddingBottom: 0,
                },
              }}
            >
              <Box sx={{ height: '100%' }}>
                <DataGrid
                  getRowId={row => callActivityData.indexOf(row)}
                  rowCount={callActivityCount}
                  paginationMode="server"
                  pagination
                  loading={loadingAllCallLogs}
                  rows={callActivityData}
                  pageSizeOptions={[25, 50, 100]}
                  paginationModel={paginationModel}
                  disableRowSelectionOnClick
                  onPaginationModelChange={setPaginationModel}
                  columns={columns}
                  density={'compact'}
                />
              </Box>
            </CardContent>
          </Card>
        </React.Fragment>
      }
    />
  );
}

export default CallActivity;
